@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/textdatafields';
@import '../../../common/assets/scss/typography';

.product-details-page,
.purchasing {
  p {
    font-size: $body-font-size;
  }

  table {
    margin-bottom: 0;
    height: fit-content;
    font-size: $body-font-size;

    th,
    td {
      border: none;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;

      &.subtle {
        color: $subtle;
      }
    }

    th {
      padding-right: 0.75rem;
      min-width: 4.75rem;
    }

    td {
      max-width: 0;
      width: 100%;
    }
  }
}

.product-details-page :global {
  .nav-tabs,
  .accordion-header {
    button {
      font-family: $plus-jakarta-sans;
      font-size: 1.125rem;
    }
  }
}

.header {
  padding-bottom: 1.75rem;
  border-bottom: 1px solid $border-colour;
  margin-bottom: 1.5rem;
}

.title,
.title a {
  color: $primary-a;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-decoration: none;
  overflow-wrap: anywhere;
  margin: 0;
}

.panel {
  margin-top: 1.25rem;
}

.section-title {
  @extend .subtitle-font;
  margin-bottom: 1.25rem;

  color: $primary-a;
}

.summary-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary-header {
  @extend .summary-layout;

  align-items: flex-start;
}

.summary-body {
  @extend .summary-layout;

  width: 100%;
  padding: 1.25rem 0;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid $border-colour;
}

.left-col {
  @extend .summary-layout;

  flex-grow: 1;
}

.right-col {
  @extend .summary-layout;
}

.product-info {
  flex-grow: 1;
  flex-basis: 0;
}

.long-description {
  margin-top: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 0;
}

.image-wrapper {
  display: flex;
  flex-shrink: 1;
  align-items: flex-start;
  aspect-ratio: 1 / 1;
  padding: 0 1.25rem;
  width: 50%;
  max-width: 22.5rem;

  svg {
    @extend .img;

    height: auto;
  }
}

.img {
  width: 100%;
  object-fit: contain;
  border: 1px solid $border-colour;
  aspect-ratio: 1 / 1;
}

.purchasing {
  min-width: 20rem;
}

.purchasing-controls-wrapper {
  min-height: 9rem;
  margin-bottom: 0.75rem;
}

.supplier {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;

  .name-wrapper {
    width: 93%;
  }
}

.btn-link {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: rgb(var(--bs-link-hover-color-rgb));
  }
}

.mobile {
  .summary-layout {
    flex-direction: column;
  }

  .summary-header {
    flex-direction: row;
  }

  .image-wrapper,
  .purchasing {
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }

  .image-wrapper {
    padding: 1.25rem 0;
    margin-bottom: 1.25rem;
  }

  .purchasing {
    margin: 1.25rem 0;
  }

  .purchasing-controls-wrapper {
    min-height: auto;
  }
}

.specifications {
  display: flex;
  font-size: $body-font-size;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .column {
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-right: 2rem;
      max-width: 31.25rem;
    }

    .specification {
      padding-bottom: 0.5rem;

      .name {
        max-width: 12.5rem;
      }

      .value {
        max-width: 18.75rem;
      }

      .name,
      .value {
        word-break: normal;
        overflow-wrap: anywhere;
      }
    }
  }
}

.documentation {
  width: 100%;
  word-break: normal;
  overflow-wrap: anywhere;

  p {
    display: inline-block;
    width: 100%;
  }
}

.purchase-history {
  &.mobile {
    padding: 0;
    padding-bottom: 0.375rem;
  }

  .full {
    th,
    td {
      width: 20%;
      padding: 0.625rem 0;

      &:first-child {
        padding-left: 0.5rem;
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
      }

      &:last-child {
        padding-right: 0.5;
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
      }
    }

    tbody tr:hover td {
      background-color: $hover-a;
    }
  }

  .compact {
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid $border-colour;

    th,
    td {
      padding: 0.25rem 0;
    }

    th {
      width: 40%;
      padding-right: 1.75rem;
    }

    td {
      padding-left: 1.75rem;
    }
  }

  p {
    font-size: $body-font-size;
    text-align: center;
  }

  .confidential-icon {
    display: inline-block;
    margin-left: 0.5rem;
  }
}
