@import '../../../common/assets/scss/variables';

.item {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: $body-font-size;
  overflow-wrap: anywhere;
  width: 100%;

  a {
    color: $primary-a;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.quantity {
  color: $subtle;
}

.img-wrapper {
  height: 2.5rem;
  max-height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
}

.img {
  object-fit: contain;
  width: inherit;
  height: inherit;
  border: 1px solid $base-colour-lightest;
}
