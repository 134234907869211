@import 'variables';

.body-font {
  font-family: $be-vietnam-pro;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: $body-color;
}

.title-font {
  font-family: $plus-jakarta-sans;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0em;
}

.subtitle-font {
  font-family: $be-vietnam-pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label-font {
  @extend .body-font;

  color: $subtle;
  font-size: 0.75rem;
  line-height: 1rem;
}
