@import 'variables';
@import 'ma-controls';

.form-control {
  @include ma-input();

  &.is-invalid {
    &:active,
    &:hover,
    &:focus,
    &:focus-visible:active {
      box-shadow: none;
      border-color: $danger;
    }
  }
}

.form-label.required::after {
  content: '*';
  display: inline-block;
  margin-left: 0.25rem;
  color: $danger;
}

.form-select {
  @include ma-select();
}

.form-label,
.invalid-feedback,
.valid-feedback {
  font-size: $body-font-size;
  line-height: 1.429;
  min-height: 1.25rem;
}

.form-check-input {
  width: 1rem;
  height: 1rem;
  border-radius: $border-radius-small;
  margin-top: 0;

  &:checked {
    background-color: inherit;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-close {
  &:focus {
    box-shadow: none;
  }
}

.spinner-border-sm {
  border-width: 0.125rem;
}
