@import '../../../common/assets/scss/variables';

.order-invoices-table {
  border-radius: $border-radius-small;
  overflow: hidden;

  table {
    margin: 0;
    background-color: $white;
    border-color: $base-colour-light;
    vertical-align: middle;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    height: 2.5rem;
    tr {
      border-top: none;
    }
    th:first-child {
      padding-left: 2rem;
    }
    th:last-child {
      padding-right: 2rem;
    }
    th {
      background-color: $secondary-colour-light-blue;
      color: $base-colour-dark-grey;
      white-space: nowrap;
      border: none;
      font-weight: normal;
    }
  }

  tbody {
    tr {
      height: 2.5rem;
    }
    td:first-child {
      padding-left: 2rem;
    }
    td:last-child {
      padding-right: 2rem;
    }
    td {
      border: none;
      word-break: normal;
      overflow-wrap: anywhere;

      &.number > a {
        color: $primary-a;
        text-decoration: none;
      }
    }
  }
}

.no-results-message {
  top: 2.5rem;
  position: relative;
  justify-self: center;
}

.order-invoices-table-mobile {
  table {
    display: table;
    table-layout: fixed;
  }
  tbody {
    td {
      word-break: normal;
      overflow-wrap: anywhere;
    }
  }
}
