@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.card {
  margin-top: 1.5rem;
  overflow: hidden;

  &.compact {
    .footer {
      flex-direction: column;
      row-gap: 0.75rem;

      .spend-summary-entries {
        align-self: flex-end;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 1.25rem;
  row-gap: 0.75rem;

  &:global(.card-header) {
    padding-bottom: 0.75rem;
  }

  :global(.badge) {
    font-size: $body-font-size;
    font-weight: 400;
    padding: 0.25rem 1.25rem;
  }

  h4 {
    @extend .subtitle-font;

    margin-bottom: 0;

    a {
      color: $primary;
      text-decoration: none;
    }
  }

  span {
    font-size: $body-font-size;
  }

  .priority-high {
    color: $danger;
  }

  .priority-low {
    color: $success;
  }
}

.body {
  p {
    margin-bottom: 0;
  }

  .confidential {
    font-weight: 700;
    color: $warning;
    text-transform: uppercase;
    margin-right: 0.5rem;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:global(.card-footer) {
    padding-top: 1.25rem;
  }

  h5 {
    font-family: $be-vietnam-pro;
    font-size: $body-font-size;
    font-weight: 700;
    margin-bottom: 0;
  }

  .spend-summary-entries {
    p {
      margin-bottom: 0;
      display: table-row;

      &:not(:last-child) {
        span,
        strong {
          padding-bottom: 0.25rem;
        }
      }

      span,
      strong {
        display: table-cell;

        &:first-child {
          color: $subtle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
          padding-right: 0.5rem;
        }

        &:last-child {
          min-width: 5rem;
          text-align: right;
        }
      }
    }
  }
}
