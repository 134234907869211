@import '../../../common/assets/scss/variables';

.link {
  text-decoration: none;

  &.mobile {
    min-width: 100%;
  }
}

.widget {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-width: 21.25rem;
  height: 10rem;
  border: solid 1px $base-colour-light;
  border-radius: $border-radius-standard;
  padding: 0 1rem 0 1rem;
  font-family: $headings-font-family;

  p {
    margin: 0;
  }

  &.mobile {
    min-width: 100%;
  }
}

.value {
  font-size: 6rem;
  color: $primary-a;

  &.zero {
    color: $base-colour-light;
  }
}

.text {
  font-size: 1.5rem;
  color: $primary-a;
}
