@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.order-items-pagination {
  margin: 0;
  padding: 1rem 0.25rem;
  background-color: $white;
  border: 1px solid $border-colour;
  border-top: none;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;

  &.standalone {
    border: 1px solid $border-colour;
    border-radius: $border-radius-small;
  }

  .page-size {
    display: flex;
    flex-direction: row;
    align-items: center;

    :global {
      label {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }

      .ma-select-trigger {
        min-height: auto;
        max-width: 6rem;
      }
    }
  }

  .total {
    font-size: $body-font-size;
    margin: 0;
  }
}
