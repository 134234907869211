@use 'sass:map';

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.title {
  @extend .subtitle-font;
  color: $primary-a;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.4375rem;
  margin-bottom: 0;

  a {
    text-decoration: none;
    padding-bottom: 1rem;
    color: $primary-a;
    margin-right: 1em;
  }
}

.header {
  h4 {
    @extend .subtitle-font;
    color: $primary-a;
  }
}

.body {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
  row-gap: 0.25rem;
}

.list {
  flex-grow: 1;
  width: min-content;
}

.notes {
  @extend .body-font;

  word-break: normal;
  overflow-wrap: anywhere;
}

@container ma-page (width < #{map.get($grid-breakpoints, "md")}) {
  .body {
    flex-direction: column;
  }

  .list {
    width: auto;
  }
}
