@import 'variables';

.dynamic-pagination {
  --bs-pagination-color: #{$body-color};
  --bs-pagination-font-size: #{$body-font-size};
  --bs-pagination-padding-x: 0;
  --bs-pagination-padding-y: 0;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: transparent;
  --bs-pagination-hover-color: #{$body-color};
  --bs-pagination-hover-bg: transparent;
  --bs-pagination-hover-border-color: transparent;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-focus-color: #{$primary-a};
  --bs-pagination-focus-bg: transparent;
  --bs-pagination-active-bg: #{$primary-a};
  --bs-pagination-active-color: #{$white};
  margin: 0;

  :global {
    .page-item {
      height: 1.25rem;
      width: fit-content;
      min-width: 1.25rem;
    }

    .page-link {
      text-align: center;
      border-radius: 0.125rem;
      padding: 0 0.25rem;

      &.active {
        background-color: $primary;
      }
    }
  }
}
