@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/ma-controls';

.order-card {
  margin-top: 1.5rem;
  padding-top: 1.25rem;

  .title {
    @extend .subtitle-font;

    color: $primary-a;
    padding-bottom: 0.75rem;
    text-decoration: none;
    overflow-wrap: anywhere;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  :global(.btn) {
    min-height: $ma-controls-min-height;
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $body-color;
      text-decoration: none;
    }
  }

  .left-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .right-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    flex-grow: 1;

    .images {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: fit-content;
      height: 5.5rem;
      overflow: hidden;
      opacity: 1;
      transition: opacity 0.2s;

      &.expanded {
        opacity: 0;
        transition: opacity 0.2s 0.5s;
      }
    }

    .img {
      object-fit: contain;
      border: none;
      width: 5.5rem;
      min-width: 5.5rem;
      height: 5.5rem;
      max-height: 5.5rem;
      padding: 0;
    }

    .text {
      display: flex;
      align-self: flex-end;
      overflow: hidden;
      justify-content: flex-end;

      .line-item-count {
        cursor: pointer;
        user-select: none;
        text-align: end;
        margin-right: 1rem;
        transition: margin-right 0.2s;

        &.expanded {
          margin-right: 0;
          transition: margin-right 0.2s 0.5s;
        }
      }

      .tooltip {
        position: absolute;
        color: $warning;
        opacity: 1;
        width: 1rem;
        transition:
          width 0.2s,
          padding 0.2s,
          opacity 0.2s;

        &.expanded {
          opacity: 0;
          width: 0;
          padding: 0;
          pointer-events: none;
          transition:
            width 0.2s 0.5s,
            padding 0.2s 0.5s,
            opacity 0.2s 0.5s;
        }
      }
    }

    .order-received {
      color: $secondary-colour-green;

      &.mobile {
        align-self: center;
      }
    }

    .ordered-by {
      overflow-wrap: anywhere;

      &.mobile {
        margin-top: 1rem;
        align-self: start;
      }
    }
  }
}

.expandable-wrapper {
  overflow: hidden;
  opacity: 0;
  transition:
    height 0.5s 0.2s,
    opacity 0.2s;

  &.expanded {
    overflow: visible;
    opacity: 1;
    transition:
      height 0.5s,
      opacity 0.2s 0.5s;
  }

  .expandable {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1rem 0 1rem;

    a {
      text-decoration: none;
      color: $body-color;
    }

    button {
      width: 100%;
      max-width: 18.75rem;
    }
  }
}

.tooltip-text {
  max-width: 12rem;
}
