@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.line-item-card {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $border-colour;
  margin-left: 2.5rem;

  &:first-child {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid $border-colour;
  }

  th {
    color: $subtle;
    font-weight: normal;
    text-align: end;
    white-space: nowrap;
    vertical-align: top;
  }

  td {
    padding-left: 1rem;

    a {
      color: $primary-a;
      text-decoration: none;
    }
  }

  .spend-categories {
    margin-top: 0.5rem;
    align-self: flex-start;
    color: $subtle;

    strong {
      cursor: pointer;
    }

    span {
      padding-inline: 0.5rem;
    }
  }

  .stock-info {
    font-size: $body-font-size;
  }

  .product-summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 0.5rem;

    .product-summary {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 1.75rem;
      width: 60%;
    }

    .img-wrapper {
      height: 5.5rem;
      width: 5.5rem;

      img,
      svg {
        object-fit: contain;
        width: 5.5rem;
        min-width: 5.5rem;
        height: 5.5rem;
        max-height: 5.5rem;
        border: 1px solid $base-colour-lightest;
        border-radius: $border-radius-small;
        padding: 0;
      }
    }

    .quantity {
      width: 10%;

      td {
        white-space: nowrap;
      }
    }

    .cost-summary {
      width: 30%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      td {
        text-align: right;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      column-gap: 0.5rem;
      margin-left: 1rem;
      min-width: 2.5rem;

      .action-btn {
        display: flex;
        align-items: flex-start;
        min-width: 1rem;
        min-height: 1rem;
      }
    }
  }

  &.compact {
    margin-inline: 0;
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;

    &:first-child {
      margin-top: 1.25rem;
      padding-top: 1.25rem;
      border-top: 1px solid $border-colour;
    }

    .cost-summary {
      align-self: flex-end;

      td {
        text-align: right;
      }
    }
  }
}
